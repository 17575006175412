/* Estilos existentes */
.music-player-button {
  background: none;
  border: none;
  color: #fdc500;
  font-size: 1.2rem;
  cursor: pointer;
  transition: all 0.3s ease;
  padding: 0.5rem;
  border-radius: 50%;
}

.music-player-button:hover {
  background-color: rgba(255, 92, 154, 0.2);
  transform: scale(1.1);
}

.response-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 1rem;
}

.response-actions button {
  margin-left: 0.5rem;
}

/* Nuevos estilos para el reproductor de música */
.music-player-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.music-player-container .music-player-button {
  margin: 0 0.25rem;
}

.music-player-button.previous,
.music-player-button.next {
  font-size: 1rem;  /* Ligeramente más pequeños que el botón de reproducción/pausa */
}

/* Ajuste para el botón de reproducción/pausa */
.music-player-button.play-pause {
  font-size: 1.2rem;  /* Mantiene el tamaño original */
}

/* Animación sutil para los botones cuando están activos */
.music-player-button:active {
  transform: scale(0.95);
}