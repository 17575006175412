.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(5px);
}

.modal-content {
  background: rgba(20, 20, 20, 0.8);
  border-radius: 15px;
  padding: 1.5rem;
  width: 90%;
  max-width: 450px;
  max-height: 70vh;
  overflow-y: auto;
  position: relative;
  box-shadow: 0 0 20px rgba(255, 92, 154, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
}

.close-button {
  position: absolute;
  top: 0.7rem;
  right: 0.7rem;
  background: none;
  border: none;
  color: #e500a4;
  font-size: 1.2rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.close-button:hover {
  color: #ff8eb7;
  transform: rotate(90deg);
}

.modal-content h2 {
  color: #ff5c9a;
  font-size: 2rem;
  margin-bottom: 1.2rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.magic-icon, .step-icon {
  margin-right: 0.4rem;
}

.tutorial-steps {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.tutorial-step {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  padding: 0.8rem;
  transition: all 0.3s ease;
}

.tutorial-step:hover {
  background: rgba(255, 255, 255, 0.1);
  transform: translateY(-3px);
}

.tutorial-step h3 {
  color: #e500a4;
  margin-bottom: 0.4rem;
  font-size: 1rem;
  display: flex;
  align-items: center;
}

.tutorial-step p {
  color: #fff;
  font-size: 0.8rem;
  line-height: 1.3;
}

.modal-content::-webkit-scrollbar {
  width: 6px;
}

.modal-content::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.1);
}

.modal-content::-webkit-scrollbar-thumb {
  background-color: #ff5c9a;
  border-radius: 3px;
}

@media (max-width: 768px) {
  .modal-content {
    padding: 1.2rem;
    max-width: 90%;
  }

  .modal-content h2 {
    font-size: 1.4rem;
  }

  .tutorial-step h3 {
    font-size: 0.9rem;
  }

  .tutorial-step p {
    font-size: 0.75rem;
  }
}

@media (max-width: 480px) {
  .modal-content {
    padding: 1rem;
    max-width: 95%;
  }

  .modal-content h2 {
    font-size: 1.3rem;
  }

  .tutorial-step h3 {
    font-size: 0.85rem;
  }

  .tutorial-step p {
    font-size: 0.7rem;
  }
}