.App {
  text-align: center;
  
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: black;
  margin: 0;
  padding: 0;
  scrollbar-width: thin;
  scrollbar-color: #333 #1a1a1a;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #1a1a1a;
}

::-webkit-scrollbar-thumb {
  background-color: #333;
  border-radius: 4px;
  border: 2px solid #1a1a1a;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #444;
}

/* Mejora del desplazamiento suave para elementos específicos */
.smooth-scroll {
  overflow-y: scroll;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

/* Ocultar scrollbar pero mantener funcionalidad */
.hide-scrollbar {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background: linear-gradient(135deg, #6a0572, #a4508b);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(0, 0, 0);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

