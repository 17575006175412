.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 1);
  z-index: 9999;
}

.loader-overlay {
  background-color: rgba(0, 0, 0, 0.8);
}

.loader {
  width: var(--loader-size, 100px);
  aspect-ratio: 1;
  color: var(--loader-color, #ff36ab);
  background:
    radial-gradient(circle at 60% 65%, currentColor 62%, #0000 65%) top left,
    radial-gradient(circle at 40% 65%, currentColor 62%, #0000 65%) top right,
    linear-gradient(to bottom left, currentColor 42%, #0000 43%) bottom left,
    linear-gradient(to bottom right, currentColor 42%, #0000 43%) bottom right;
  background-size: 50% 50%;
  background-repeat: no-repeat;
  position: relative;
}

.loader:after {
  content: "";
  position: absolute;
  inset: 0;
  background: inherit;
  opacity: 0.4;
  animation: loaderPulse 1s infinite;
}

.loader-message {
  margin-top: 1rem;
  color: var(--loader-color, #ff36ab);
  font-family: var(--body-font);
  font-size: 1.2rem;
  text-align: center;
}

@keyframes loaderPulse {
  to {
    transform: scale(1.8);
    opacity: 0;
  }
}