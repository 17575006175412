/* MEDIA QUERIES */

/* MEDIA QUERY FOR SCREENS SMALLER THAN 1024PX */
@media (max-width: 1024px) {
    .home-dream-feature {
      flex-basis: calc(50% - 2rem);
    }
  }
  
  /* MEDIA QUERY FOR SCREENS SMALLER THAN 768PX */
  @media (max-width: 768px) {
    .home-dream-feature {
      flex-basis: 100%;
    }
  
    .home-dream-feature-title {
      font-size: 1.6rem;
    }
  
    .home-dream-feature-icon {
      font-size: 2.5rem;
    }
  }
  
  /* MEDIA QUERY FOR SCREENS SMALLER THAN 1200PX */
  @media (max-width: 1200px) {
    .home-title {
      font-size: 6rem;
      margin-top: -8rem;
    }
  
    .home-card-container {
      flex-direction: column;
      align-items: center;
    }
  
    .home-card-wrapper {
      flex: 0 1 auto;
      width: 80%;
      max-width: 400px;
      margin-bottom: 2rem;
    }
  }
  
  /* MEDIA QUERY FOR SCREENS SMALLER THAN 768PX */
  @media (max-width: 768px) {
    .home-title {
      font-size: 4rem;
      margin-top: -6rem;
    }
  
    .home-subtitle {
      font-size: 1.8rem;
    }
  
    .home-description {
      font-size: 1.1rem;
    }
  
    .home-section-title {
      font-size: 3rem;
    }
  
    .home-feature-title {
      font-size: 1.6rem;
    }
  
    .home-victoria-showcase {
      flex-direction: column;
    }
  
    .home-victoria-image {
      width: 100%;
      margin-bottom: 2rem;
    }
  
    .home-victoria-text {
      padding-left: 0;
    }
  
    .home-dream-feature {
      flex-basis: 100%;
    }
  }
  
  /* MEDIA QUERY FOR SCREENS SMALLER THAN 480PX */
  @media (max-width: 480px) {
    .home-title {
      font-size: 4rem;
      margin-top: -4rem;
    }
  
    .home-subtitle {
      font-size: 1.5rem;
    }
  
    .home-section-title {
      font-size: 2.5rem;
      margin-top: -3rem;
      text-wrap:balance;
    }
  
    .home-section-description {
      font-size: 1.1rem;
      width: 100%;
      text-wrap:balance;

    }
    .home-card-description {
        font-size: 1rem;
    }

    .home-card-title {
        font-size: 2rem;

    }
  
    .home-artist-description {
      font-size: 1.1rem;
    }
  
    .home-dream-text {
      font-size: 0.9rem;
      text-align: center;
    }

    .home-dream-art-description {
        font-size: 1rem;
    }
  }
  
  /* ANIMATION KEYFRAMES */
  @keyframes float {
    0% { transform: translateY(0px); }
    50% { transform: translateY(-10px); }
    100% { transform: translateY(0px); }
  }
  
  /* CARD ANIMATION DELAYS */
  .home-card-wrapper:nth-child(2) {
    animation-delay: -2s;
  }
  
  .home-card-wrapper:nth-child(3) {
    animation-delay: -4s;
  }
  
  /* AI ART SHOWCASE */
  .home-ai-art-showcase {
    padding: 5rem 2rem;
    text-align: center;
    margin-top: 0rem;
  }
  
  /* CAROUSEL SLIDE IMAGES */
  .home-carousel-slide img {
    max-height: 500px;
    object-fit: contain;
  }
  
  /* TESTIMONIALS SECTION */
  .home-testimonials {
    padding: 5rem 2rem;
    background-color: var(--primary-color);
    z-index: 10;
  }
  
  /* TESTIMONIAL GRID */
  .home-testimonial-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
  }
  
  /* INDIVIDUAL TESTIMONIAL */
  .home-testimonial {
    background-color: var(--secondary-color);
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0,0,0,0.1);
    position: relative;
    z-index: 3;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.35);
    backdrop-filter: blur(4px);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  /* QUOTE ICON */
  .home-quote-icon {
    font-size: 2rem;
    color: var(--text-color);
    opacity: 0.2;
    position: absolute;
    top: 1rem;
    left: 1rem;
  }
  
  /* TESTIMONIAL TEXT */
  .home-testimonial-text {
    font-family: var(--body-font);
    font-style: italic;
    margin-bottom: 1rem;
    font-size: 1.0rem;
    line-height: 1.6;
    color: rgb(255, 255, 255);
    margin-top: 2rem;
  }
  
  /* TESTIMONIAL FOOTER */
  .home-testimonial-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
  }
  
  /* TESTIMONIAL NAME */
  .home-testimonial-name {
    font-family: var(--title-font);
    font-weight: bold;
    color: var(--text-color);
  }
  
  /* TESTIMONIAL FLAG */
  .home-testimonial-flag {
    width: 24px;
    height: 16px;
    object-fit: cover;
  }
  
  /* VICTORIA SHOWCASE SECTION */
  .home-victoria-showcase {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5rem 2rem;
  }
  
  /* VICTORIA IMAGE CONTAINER */
  .home-victoria-image-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: -3rem;
    margin-top: -10rem;
  }
  
  /* VICTORIA IMAGE */
  .home-victoria-image {
    width: 100%;
    max-width: 700px;
    border-radius: 10px;
    box-shadow: 
      0 10px 20px rgba(0, 0, 0, 0.2), /* Sombra original para profundidad */
      0 0 5px rgba(0, 0, 0, 0.5),
      0 0 10px rgba(0, 0, 0, 0.5),
      0 0 20px rgba(0, 0, 0, 0.5),
      0 0 30px rgba(0, 0, 0, 0.5);
    transform: rotate(0deg);
    margin-top: 10rem;
    margin-bottom: 0rem;
    transition: box-shadow 0.3s ease-in-out; /* Transición suave */
}


  
  /* VICTORIA TEXT */
  .home-victoria-text {
    text-align: center;
    max-width: 800px;
    margin-top: -10%;
  }
  
  /* VICTORIA TITLE */
  .home-victoria-title {
    font-family: var(--title-font);
    font-size: 2.5rem;
    margin-bottom: 1rem;
    margin-top: 0rem;
    color: var(--text-color);
  }
  
  /* VICTORIA DESCRIPTION */
  .home-victoria-description {
    font-family: var(--body-font);
    font-size: 1.1rem;
    color: var(--light-text);
  }
  
  /* SUBSCRIPTION SECTION */
.home-subscription-section {
  padding: 5rem 1.5rem;
  background-color: var(--primary-color);
  text-align: center;
  margin-top: 10rem;
}

/* SUBSCRIPTION OPTIONS */
.home-subscription-options {
  display: flex;
  justify-content: center;
  gap: 2.5rem;
  flex-wrap: wrap;
  margin-top: 10rem;
}

/* INDIVIDUAL SUBSCRIPTION OPTION */
.home-subscription-option {
  background: rgba(10, 9, 9, 0.5);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5), 0 6px 20px rgba(0, 0, 0, 0.4);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  padding: 20px;
  color: white;
  flex: 1;
  min-width: 250px;
  max-width: 350px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.home-subscription-option:hover {
  transform: translateY(-8px);
  box-shadow: 0 12px 24px rgba(219, 217, 217, 0.2);
}

/* FEATURED SUBSCRIPTION OPTION */
.home-subscription-option-featured {
  background: rgba(10, 9, 9, 0.5);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5), 0 6px 20px rgba(0, 0, 0, 0.4);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  padding: 20px;
  color: white;
}

/* SUBSCRIPTION TITLE */
.home-subscription-title {
  font-family: var(--title-font);
  font-size: 1.9rem;
  margin-bottom: 0.8rem;
  background: linear-gradient(90deg, #5932e6, #FF1493, #ff8fab);
  background-size: 300%;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  animation: gradient-animation 5s ease infinite;
}

@keyframes gradient-animation {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

/* SUBSCRIPTION PRICE */
.home-subscription-price {
  font-family: var(--title-font);
  font-size: 2.1rem;
  font-weight: bold;
  color: #affc41;
  margin-bottom: 1.2rem;
}

/* SUBSCRIPTION FEATURES LIST */
.home-subscription-features {
  list-style-type: none;
  padding: 0;
  margin-bottom: 1.2rem;
  text-align: left;
}

.home-subscription-features li {
  font-family: var(--body-font);
  font-size: 1.0rem;
  margin-bottom: 0.4rem;
  color: var(--light-text);
  text-wrap: balance;
}
  
  /* MEDIA QUERY FOR TABLETS */
  @media (max-width: 768px) {
    .home-subscription-section {
      padding: 3rem 1rem;
    }
  
    .home-subscription-options {
      gap: 1rem;
    }
  
    .home-subscription-option {
      width: 230px;
      padding: 1.2rem;
    }
  
    .home-subscription-title {
      font-size: 1.4rem;
    }
  
    .home-subscription-price {
      font-size: 1.6rem;
    }
  
    .home-subscription-features li {
      font-size: 0.85rem;
    }
  }
  
  /* MEDIA QUERY FOR MOBILE PHONES */
  @media (max-width: 480px) {
    .home-subscription-section {
      padding: 2rem 0.5rem;
    }
  
    .home-subscription-options {
      flex-direction: column;
      align-items: center;
      margin-top: 0rem;
    }
  
    .home-subscription-option {
      width: 90%;
      max-width: 280px;
      padding: 1rem;
      margin-bottom: 1rem;
    }
  
    .home-subscription-title {
      font-size: 1.3rem;
    }
  
    .home-subscription-price {
      font-size: 1.5rem;
    }
    .home-victoria-image{
      margin-top: 5rem;
      margin-bottom: -5rem;
    }
  
    .home-subscription-features li {
      font-size: 0.8rem;
    }
  }
  
  /* CRYPTO ACCEPTED SECTION */
  .home-crypto-accepted {
    margin-top: 10rem;
    color: var(--text-color);
    font-size: 2.7rem;
    font-family: var(--title-font);

    
  }

  .crypto-option {
    flex: 1;
    max-width: 200px;
    padding: 1.5rem;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .crypto-option:hover {
    transform: translateY(-5px);
  }

  .crypto-option-title {
    font-size: 2.2rem;
    margin-bottom: 0.5rem;
    color: #dcd9d9;
    text-wrap:balance;
  }
  
  .home-crypto-accepted p {
    font-family: var(--title-font);
    font-size: 1.2rem;
    margin-bottom: 1.2rem;
    color: #636161;
  }
  
  .home-crypto-icons {
    display: flex;
    justify-content: center;
    gap: 2.5rem;
    font-size: 4rem;
    z-index: 30;
  }
  
  

  @media (max-width: 576px) {
    .home-crypto-accepted {
      margin-top: 3rem;
      font-size: 1.45rem;
    }
  
    .crypto-option {
      max-width: 150px;
      padding: 1rem;
    }
  
    .crypto-option-title {
      font-size: 1.6rem;
    }
  
    .home-crypto-accepted p {
      font-size: 1rem;
    }
  
    .home-crypto-icons {
      gap: 1.5rem;
      font-size: 3rem;
    }
  }
  
  /* Breakpoint para móviles pequeños */
  @media (max-width: 375px) {
    .home-crypto-accepted {
      margin-top: 2.5rem;
      font-size: 1.4rem;
    }
  
    .crypto-option {
      max-width: 130px;
      padding: 0.8rem;
    }
  
    .crypto-option-title {
      font-size: 1.6rem;
    }
  
    .home-crypto-accepted p {
      font-size: 0.9rem;
    }
  
    .home-crypto-icons {
      gap: 1rem;
      font-size: 2.5rem;
    }
  }
  
  
  /* FINAL CALL TO ACTION SECTION */
  .home-final-cta {
    text-align: center;
    padding: 6rem 5rem;
    background: linear-gradient(135deg, #27272b, #e16be6);
    position: relative;
    overflow: hidden;
  }
  
  .home-final-cta::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: inherit;
    filter: blur(10px);
    opacity: 0.7;
    z-index: -1;
  }
  
  .home-final-cta-content {
    position: relative;
    z-index: 1;
  }
  
  .home-final-cta-title {
    font-family: var(--title-font);
    font-size: 3rem;
    margin-bottom: 1rem;
    color: var(--light-text);
  }
  
  .home-final-cta-description {
    font-family: var(--body-font);
    font-size: 1.2rem;
    margin-bottom: 2rem;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    color: var(--light-text);
    opacity: 0.9;
  }
  
  /* MEDIA QUERY FOR FINAL CALL TO ACTION */
  @media (max-width: 768px) {
    .home-subscription-option {
      width: 100%;
    }
  
    .home-final-cta {
      padding: 5rem 2rem;
    }
  
    .home-final-cta-title {
      font-size: 2.5rem;
    }
  
    .home-final-cta-description {
      font-size: 1.1rem;
    }
  }
  
  /* MEDIA QUERY FOR FINAL CALL TO ACTION ON MOBILE */
  @media (max-width: 480px) {
    .home-subscription-section {
      padding: 3rem 1rem;
    }
  
    .home-subscription-title {
      font-size: 2.0rem;
    }
  
    .home-subscription-price {
      font-size: 1.8rem;
    }
  
    .home-crypto-accepted p {
      font-size: 1rem;
    }
  
    .home-crypto-icons {
      font-size: 3rem;
    }
  
    .home-final-cta-title {
      font-size: 2rem;
    }
  
    .home-final-cta-description {
      font-size: 1rem;
    }
  }

  @keyframes lightning-flash {
    0% {
      opacity: 1;
      transform: scale(1);
    }
    25% {
      opacity: 0.5;
      transform: scale(1.1);
    }
    50% {
      opacity: 1;
      transform: scale(1);
    }
    75% {
      opacity: 0.5;
      transform: scale(1.2);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  /* CTA FINAL SECTION */

  .home-final-cta-description .cta-features {
    list-style-type: none;
    padding: 0;
    margin: 1rem 0;
    text-align: left;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .home-final-cta-description .cta-features li {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    color: var(--light-text);
  }
  
  .home-final-cta-description .cta-features .icon {
    width: 24px; /* Tamaño fijo para el ancho */
    height: 24px; /* Tamaño fijo para el alto */
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5rem;
    color: var(--light-text);
    /* Si usas iconos de fuente, descomenta la línea siguiente */
    /* font-size: 24px; */
  }
  
  /* Para asegurar que los SVGs se ajusten al contenedor */
  .home-final-cta-description .cta-features .icon svg {
    width: 100%;
    height: 100%;
    display: block;
  }
  

.home-final-cta-description .highlight {
  color: #ff36ab;
  font-weight: bold;
}

.sign-in-button {
  display: inline-flex;
  align-items: center;
  background-color: var(--light-text);
  color: #27272b;
  padding: 0.75rem 1.5rem;
  border-radius: 25px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.sign-in-button:hover {
  background-color: #e16be6;
  color: var(--light-text);
}

.sign-in-button .icon {
  margin-left: 0.5rem;
}

@media (max-width: 768px) {
  .home-final-cta-description .cta-features {
    font-size: 1rem;
  }
  
}

@media (max-width: 480px) {
  .home-final-cta-description .cta-features {
    font-size: 0.9rem;
  }
}
  
  .lightning-icon {
    color: #e4ff1a;  /* Color dorado para el rayo */
    animation: lightning-flash 2s infinite;
  }
  
  .lightning-icon:hover {
    animation: lightning-flash 0.5s infinite;
  }

  .crypto-icon {
    color: #ff5c9a; /* Color dorado para el rayo */
  
  }
  
  /* SUBSCRIPTION ICONS */
  .home-subscription-icons {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
  }
  
  .home-subscription-icon {
    font-size: 3rem;
    margin: 0 0.5rem;
    color: #237be8;
  }
  
  .home-subscription-option-featured .home-subscription-icon {
    color: #deff0a;
    margin-top: 1rem;
  }
  
  /* SATOSHI BACK SECTION */
  .home-satoshi-back {
    padding: 4rem 2rem;
    color: var(--light-text);
  }
  
  .home-satoshi-back-content {
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
  }
  
  .home-satoshi-back-description {
    font-size: 1.2rem;
    margin-bottom: 4rem;
    text-wrap: balance;
  }
  
  .home-satoshi-back-features {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 2rem;
    margin-bottom: 3rem;
  }
  
  .home-satoshi-back-feature {
    flex-basis: calc(33.333% - 2rem);
    background-color: var(--secondary-color);
    padding: 2rem;
    border-radius: 15px;
    margin-bottom: 2rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.35);
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    z-index: 11;
    color: #f1f1f1;
  }
  
  .home-satoshi-back-feature:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(255, 255, 255, 0.3), 0 6px 20px rgba(255, 255, 255, 0.2);
  }
  
  .home-satoshi-back-icon {
    font-size: 3rem;
    color: #ffd500;
    margin-bottom: 0rem;
  }
  
  .home-satoshi-back-feature h3 {
    font-size: 1.4rem;
    color: #ff36ab;
    margin-bottom: 1rem;
  }
  
  .home-satoshi-back-feature p {
    font-size: 1rem;
    font-family: var(--body-font);
  }
  
  .home-satoshi-back-cta {
    background-color: rgba(255, 255, 255, 0.1);
    padding: 2rem;
    border-radius: 15px;
    margin-top: 3rem;
  }
  
  .home-satoshi-back-cta p {
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
  }
  
  /* CALL TO ACTION BUTTON */
  .home-cta-button {
    background-color: #f72585;
    color: white;
    border: none;
    padding: 0.8rem 1.5rem;
    font-size: 1.1rem;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .home-cta-button:hover {
    background-color: #ff6b6b;
  }
  
  /* MEDIA QUERY FOR SATOSHI BACK SECTION ON TABLETS */
  @media (max-width: 768px) {
    .home-satoshi-back-feature {
      flex-basis: 100%;
    }
  }
  
  /* INTRODUCTION FOR PAYMENT */
  .intro-pay {
    font-size: 1rem;
    color: #bebebc;
    font-family: var(--body-font);
  }
  
  /* NEW STYLES FOR DREAM ROOM SECTION */
  .home-dream-intro {
    font-family: var(--body-font);
    font-size: 1.2rem;
    max-width: 800px;
    margin: 0 auto 2rem;
    color: var(--light-text);
    opacity: 0.9;
  }
  
  .home-dream-art-description {
    font-family: var(--body-font);
    font-size: 1.2rem;
    max-width: 800px;
    margin: 0 auto 3rem;
    color: var(--light-text);
    opacity: 0.9;
  }
  
  .home-dream-experience-highlight {
    font-family: var(--title-font);
    font-size: 1.2rem;
    background: linear-gradient(135deg, #adff02 0%, #f15bb5 50%, #9b5de5 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
    margin-top: 2rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-wrap: balance;
  }
  
  /* MEDIA QUERIES FOR DREAM ROOM SECTION */
  @media (max-width: 1200px) {
    .home-dream-intro,
    .home-dream-art-description,
    .home-dream-experience-highlight {
      font-size: 1.1rem;
    }
  }
  
  @media (max-width: 992px) {
    .home-dream-intro,
    .home-dream-art-description,
    .home-dream-experience-highlight {
      font-size: 1rem;
    }
  }
  
  @media (max-width: 768px) {
    .home-dream-intro,
    .home-dream-art-description,
    .home-dream-experience-highlight {
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 576px) {
    .home-dream-intro,
    .home-dream-art-description,
    .home-dream-experience-highlight {
      font-size: 0.85rem;
    }
  }
  
  