@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700;900&family=Open+Sans:wght@400;600&display=swap');

/* VARIABLES */
:root {
  --primary-color: #080808;
  --secondary-color: #0f0f10;
  --accent-color: #0f3460;
  --text-color: #ff36ab;
  --light-text: #f1f1f1;
  --background: #0f0f0f;
  --title-font: 'Montserrat', sans-serif;
  --body-font: 'Open Sans', sans-serif;
}

/* BASE STYLES */
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow-x: hidden;
  font-family: var(--body-font);
  line-height: 1.6;
}

/* HOME CONTAINER */
.home-container {
  position: relative;
  min-height: 100vh;
  background: #080808;
  overflow-x: hidden;
}

/* HOME HERO SECTION */
.home-hero {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2rem;
  position: relative;
  z-index: 0;
}

/* TITLE AND TEXT */
.home-title {
  font-family: var(--title-font);
  font-size: 8rem;
  margin-bottom: 2rem;
  margin-top: 0rem;
  color: #ff36ab;
  text-shadow: 
    4px 4px 8px rgba(0,0,0,0.3),
    0 0 20px rgba(233, 69, 96, 0.5),
    0 0 40px rgba(233, 69, 96, 0.3);
  font-weight: 900;
  letter-spacing: -0.05em;
  line-height: 0.9;
  transform: skew(-5deg);
  transition: all 0.3s ease;
}

.home-title:hover {
  transform: skew(-5deg) scale(1.05);
  text-shadow: 
    6px 6px 12px rgba(0,0,0,0.4),
    0 0 30px rgba(233, 69, 96, 0.6),
    0 0 60px rgba(233, 69, 96, 0.4);
}

.home-subtitle {
  font-family: var(--title-font);
  font-size: 2.2rem;
  font-weight: 700;
  max-width: 800px;
  margin-bottom: 2rem;
  color: var(--light-text);
}

.home-description {
  font-family: var(--body-font);
  font-size: 1.2rem;
  max-width: 800px;
  margin-bottom: 3rem;
  opacity: 0.9;
  text-wrap:balance;
  color:#ff36ab;
}

/* CTA BUTTON */
.home-cta-button {
  font-family: var(--title-font);
  background-color: var(--text-color);
  color: var(--light-text);
  border: none;
  padding: 1rem 2rem;
  font-size: 1.1rem;
  font-weight: 600;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.home-cta-button:hover {
  background-color: var(--accent-color);
  transform: translateY(-3px);
  box-shadow: 0 4px 15px rgba(0,0,0,0.2);
}

/* FEATURES SECTION */
/* Estilos base */
.home-features {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 3rem;
  padding: 5rem 2rem;
  background-color: var(--primary-color);
}

/* Media query para iPads en orientación vertical y horizontal */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {
  .home-features {
    grid-template-columns: repeat(2, 1fr); /* Fuerza 2 columnas */
    justify-content: center;
  }
  
  /* Para centrar el tercer elemento */
  .home-features > *:last-child:nth-child(2n + 1) {
    grid-column: 1 / -1;
    max-width: 300px;
    justify-self: center;
  }
}

/* Si necesitas ajustar para iPads más grandes como el iPad Pro */
@media only screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1366px)
  and (-webkit-min-device-pixel-ratio: 2) {
  .home-features {
    grid-template-columns: repeat(3, 1fr); /* Permite 3 columnas si hay espacio */
  }
}

/* TAROT FEATURES */
.home-tarot-features {
  list-style-type: none;
  padding-left: 0;
  margin: 2rem 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}

.home-tarot-features li {
  font-size: 1rem;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 0.75rem 1.5rem;
  border-radius: 25px;
  color: var(--light-text);
  transition: all 0.3s ease;
}

.home-tarot-features li:hover {
  background-color: rgba(255, 255, 255, 0.2);
  transform: translateY(-3px);
}

/* ARTIST INFO */
.home-artist-info {
  margin: 3rem auto;
  padding: 2rem;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  max-width: 800px;
}

.home-artist-description {
  color: #ffd500;
  font-family: var(--title-font);
  text-align: right;
}

/* CARD CONTAINER */
.home-card-container {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-top: 3rem;
}

.home-card-wrapper {
  flex: 0 1 300px;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  padding: 1.5rem;
  transition: all 0.3s ease;
}

.home-tarot-card {
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 1rem;
}

.home-card-title {
  font-size: 2rem;
  margin-bottom: 0.5rem;
  color:#ff36ab;
}

.home-card-description {
  font-size: 1rem;
  opacity: 0.8;
  color: #f1f1f1;
  font-family: var(--body-font);
}

/* FEATURE ICONS AND TITLES */
.home-feature-icon {
  font-size: 3rem;
  color: #ffd500;
  margin-bottom: 1rem;
}

.home-feature-title {
  font-family: var(--title-font);
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: var(--text-color);
  text-wrap:balance;
}

.home-feature-description {
  flex-basis: calc(33.333% - 2rem);
    background-color: var(--secondary-color);
    padding: 2rem;
    border-radius: 15px;
    margin-bottom: 2rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.35);
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    z-index: 11;
    color: #f1f1f1;
}

/* TAROT SHOWCASE */
.home-tarot-showcase {
  padding: 5rem 0;
  text-align: center;
  background-color: var(--primary-color);
  z-index: 3;
}

/* SECTION TITLES AND DESCRIPTIONS */
.home-section-title {
  font-family: var(--title-font);
  font-size: 3.5rem;
  margin-bottom: 3rem;
  color: var(--text-color);
  margin-top: 0%;
  font-weight: 700;
}

.home-section-description,
.home-dream-paragraph {
  font-family: var(--body-font);
  font-size: 1.2rem;
  max-width: 800px;
  margin: 0 auto 3rem;
  color: var(--light-text);
  opacity: 0.9;
}

/* DREAM ROOM */
.home-dream-room {
  padding: 5rem 2rem;
  text-align: center;
  margin-top: 0rem;
}

.home-dream-content {
  max-width: 800px;
  margin: 0 auto;
}

.home-dream-text {
  font-family: var(--body-font);
  font-size: 1.1rem;
  color: var(--light-text);
  margin-bottom: 2rem;
}

.home-dream-features {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 5rem;
  margin-bottom: 2rem;
  position: relative;
  z-index: 10;
}

.home-dream-feature {
  flex-basis: calc(33.333% - 2rem);
    background-color: var(--secondary-color);
    padding: 2rem;
    border-radius: 15px;
    margin-bottom: 2rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.35);
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    z-index: 11;
    color: #f1f1f1;
}

.home-dream-feature:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(255, 255, 255, 0.3), 0 6px 20px rgba(255, 255, 255, 0.2);
}

.home-dream-feature-icon {
  font-size: 3rem;
  color: #ffd500; /* Color consistente con otros iconos */
  margin-bottom: 1.5rem;
  transition: color 0.3s ease;
}

.home-dream-feature:hover .home-dream-feature-icon {
  color: #f72585; /* Cambio de color al hacer hover */
}

.home-dream-feature-title {
  font-family: var(--title-font);
  font-size: 1.8rem;
  color: #ff36ab;
  margin-bottom: 1rem;
  font-weight: 700;
}

.home-dream-feature-description {
  font-family: var(--body-font);
  font-size: 1rem;
  color: var(--light-text);
  line-height: 1.6;
}

/* Base styles for .payment-crypto */
.payment-crypto {
  font-family: var(--title-font);
  font-size: 1.3rem;
  margin-bottom: -5rem;
  color: whitesmoke;
  margin-top: 0%;
  font-weight: 100;
}

/* Media query for tablets (iPad) */
@media screen and (max-width: 1024px) and (min-width: 768px) {
  .payment-crypto {
    font-size: 1.3rem;
    margin-bottom: 0.0rem;
  }
}

/* Media query for large mobile phones */
@media screen and (max-width: 767px) and (min-width: 480px) {
  .payment-crypto {
    font-size: 1.1rem;
    margin-bottom: 2rem;
  }
}

/* Media query for small mobile phones */
@media screen and (max-width: 479px) {
  .payment-crypto {
    font-size: 1.0rem;
    margin-bottom: 2rem;
    line-height: 1.5;
    text-align: center;
  }
}

/* MEDIA QUERIES */

/* MEDIA QUERY FOR SCREENS SMALLER THAN 1200PX */
@media (max-width: 1200px) {
  .home-section-title {
    font-size: 2.2rem;
  }

  .home-section-description,
  .home-dream-paragraph {
    font-size: 1.2rem;
    max-width: 700px;
    margin-bottom: 2.5rem;
  }

  .home-card-container {
    gap: 1.5rem;
  }

  .home-card-wrapper {
    flex: 0 1 250px;
  }
}

/* MEDIA QUERY FOR SCREENS SMALLER THAN 992PX */
@media (max-width: 992px) {
  .home-tarot-showcase {
    padding: 4rem 1.5rem;
  }

  .home-section-title {
    font-size: 2rem;
  }

  .home-section-description,
  .home-dream-paragraph {
    font-size: 1.1rem;
    max-width: 600px;
    margin-bottom: 2rem;
  }

  .home-card-container {
    flex-wrap: wrap;
  }

  .home-card-wrapper {
    flex: 0 1 calc(50% - 1rem);
  }
}

/* MEDIA QUERY FOR SCREENS SMALLER THAN 768PX */
@media (max-width: 768px) {
  .home-tarot-showcase {
    padding: 3rem 1rem;
  }

  .home-section-title {
    font-size: 1.8rem;
  }

  .home-section-description,
  .home-dream-paragraph {
    font-size: 1rem;
    max-width: 90%;
    margin-bottom: 1.8rem;
  }

  .home-tarot-features {
    flex-direction: column;
    align-items: center;
  }

  .home-tarot-features li {
    width: 100%;
    max-width: 300px;
  }

  .home-artist-info {
    padding: 1.5rem;
  }

  .home-artist-description {
    font-size: 1rem;
  }

  .home-card-wrapper {
    flex: 0 1 100%;
    max-width: 300px;
    margin: 0 auto;
  }
}

/* MEDIA QUERY FOR SCREENS SMALLER THAN 576PX */
@media (max-width: 576px) {
  .home-section-description,
  .home-dream-paragraph {
    font-size: 0.9rem;
    margin-bottom: 1.5rem;
  }
}

/* MEDIA QUERY FOR SCREENS SMALLER THAN 480PX */
@media (max-width: 480px) {
  .home-tarot-showcase {
    padding: 2rem 0.5rem;
  }

  .home-section-title {
    font-size: 1.6rem;
  }

  
  .home-dream-paragraph {
    font-size: 0.85rem;
    margin-bottom: 1.2rem;
  }

  .home-artist-description {
    font-size: 0.65rem;
  }

  .home-tarot-features li {
    font-size: 0.9rem;
    padding: 0.6rem 1.2rem;
  }

  .home-card-title {
    font-size: 1.2rem;
  }

  .home-card-description {
    font-size: 0.8rem;
  }
}


/* New Twitter Embed Section Styles */
.home-twitter-embed {
  background: linear-gradient(135deg, var(--background-secondary), var(--background-primary));
  padding: 4rem 2rem;
  text-align: center;
  border-radius: 10px;
  margin: 2rem 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.home-twitter-embed .home-section-title {
  color: var(--text-color);
  font-size: 3.0rem;
  margin-bottom: 1rem;
}

.home-twitter-description {
  color: var(--light-text);
  font-size: 1.1rem;
  max-width: 800px;
  margin: 0 auto 2rem;
  line-height: 1.6;
  text-wrap:balance;
}

.twitter-embed-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

/* Ensure the Twitter embed is centered */
.twitter-tweet {
  margin-left: auto !important;
  margin-right: auto !important;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .home-twitter-embed {
    padding: 3rem 1.5rem;
  }

  .home-twitter-embed .home-section-title {
    font-size: 2rem;
  }

  .home-twitter-description {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .home-twitter-embed {
    padding: 2rem 1rem;
  }

  .home-twitter-embed .home-section-title {
    font-size: 1.8rem;
  }

  .home-twitter-description {
    font-size: 0.9rem;
    
  }
}

/*ORDINALS SECTION*/

/* Ensure consistent styling with other sections */
.home-twitter-embed,
.home-satoshi-back,
.home-final-cta {
  margin-bottom: 4rem;
}

.home-section-title-ordinals {
  font-family: var(--title-font);
  font-size: 3.3rem;
  margin-bottom: 3rem;
  color: var(--text-color);
  margin-top: -5%;
  font-weight: 700;
  text-wrap: balance;
}

.home-ordinals-nft {
  padding: 4rem 2rem;
  border-radius: 20px;
  margin: 2rem 0;
}

.ordinals-nft-container {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-top: 2rem;
}

.ordinal-card {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 15px;
  padding: 1rem;
  width: 200px;
  text-align: center;
  transition: all 0.3s ease;
}

.ordinal-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(255, 54, 171, 0.3);
}

.ordinal-card img {
  width: 100%;
  border-radius: 10px;
  margin-bottom: 1rem;
}

.ordinal-card h3 {
  color: var(--text-color);
  font-family: var(--title-font);
  font-size: 1rem;
  margin-top: 0.5rem;
}

.ordinals-description {
  max-width: 800px;
  margin: 2rem auto;
  text-align: center;
  color: var(--light-text);
  font-size: 1.1rem;
  line-height: 1.6;
}

.explore-ordinals-button {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  background-color: var(--text-color);
  color: var(--primary-color);
  padding: 0.8rem 1.5rem;
  border-radius: 30px;
  text-decoration: none;
  font-weight: bold;
  transition: all 0.3s ease;
  font-size: 1.1rem;
}

.explore-ordinals-button:hover {
  background-color: #ff5cbe; /* Un tono más claro de var(--text-color) */
  color: var(--primary-color);
  transform: translateY(-3px);
  box-shadow: 0 5px 15px rgba(255, 54, 171, 0.4);
}

.explore-ordinals-button:active {
  transform: translateY(-1px);
  box-shadow: 0 2px 8px rgba(255, 54, 171, 0.4);
}

@media (max-width: 768px) {
  .ordinals-nft-container {
    flex-direction: column;
    align-items: center;
  }

  .home-section-title-ordinals {
    font-size: 2rem;
    margin-top: -10rem;
  }

  .ordinal-card {
    width: 80%;
    max-width: 300px;
  }
}





