.ordinals-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  color: var(--light-text);
}

h1 {
  font-family: var(--title-font);
  font-size: 5rem;
  margin-bottom: 2rem;
  margin-top: 1rem;
  color: #ff36ab;
  text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3), 0 0 20px rgba(233, 69, 96, 0.5),
    0 0 40px rgba(233, 69, 96, 0.3);
  font-weight: 900;
  letter-spacing: -0.05em;
  line-height: 0.9;
  transform: skew(-5deg);
  transition: all 0.3s ease;
  text-wrap: balance;
}

.ordinals-description {
  text-align: center;
  margin-bottom: 2rem;
  font-family: var(--body-font);
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  text-wrap: balance;
}

.gamma-link {
  display: inline-block;
  margin-top: 1rem;
  color: var(--cyberpunk-pink);
  text-decoration: none;
  font-weight: bold;
  transition: all 0.3s ease;
}

.gamma-link:hover {
  color: var(--lightning-yellow);
  text-shadow: 0 0 5px var(--lightning-yellow);
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 2rem;
}

.card-item {
  background: rgba(15, 15, 16, 0.3);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  padding: 1rem;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.card-item:hover {
  box-shadow: 0 6px 12px rgba(173, 255, 2, 0.3);
  border-color: var(--cyberpunk-pink);
}

.card-item img {
  width: 100%;
  height: auto;
  border-radius: 5px;
  margin-bottom: 0.5rem;
}

.card-item p {
  font-family: var(--body-font);
  color: var(--light-text);
}

.card-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.8),
    rgba(255, 54, 171, 0.4)
  );
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 20px;
}

.card-modal {
  background: rgba(15, 15, 16, 0.9);
  backdrop-filter: blur(20px);
  border-radius: 20px;
  padding: 2rem;
  max-width: 90%;
  width: 400px;
  max-height: 78vh;
  overflow-y: auto;
  text-align: center;
  position: relative;
  border: 8px solid black;
  display: flex;
  flex-direction: column;
  align-items: center;
  scrollbar-width: thin;
  scrollbar-color: var(--cyberpunk-pink) rgba(15, 15, 16, 0.5);
}

.card-modal::-webkit-scrollbar {
    width: 8px;
  }

  .card-modal::-webkit-scrollbar-track {
    background: rgba(15, 15, 16, 0.5);
    border-radius: 10px;
  }
  
  .card-modal::-webkit-scrollbar-thumb {
    background-color: var(--cyberpunk-pink);
    border-radius: 10px;
    border: 2px solid rgba(15, 15, 16, 0.5);
  }
  
  .card-modal::-webkit-scrollbar-thumb:hover {
    background-color: var(--lightning-yellow);
  }

  .card-modal-content {
    padding-right: 15px; /* Espacio para el scrollbar */
  }

.card-description {
  margin-bottom: 1rem;
  font-size: 0.9rem;
  color: var(--cyberpunk-pink);
}

.mint-description {
  margin-bottom: 1rem;
  font-size: 0.85rem;
  color: var(--light-text);
  font-style: italic;
}

.collection-hint {
  margin-top: 1rem;
  font-size: 0.8rem;
  color: var(--lightning-yellow);
  text-wrap: balance;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: var(--light-text);
  font-size: 1.5rem;
  cursor: pointer;
}

.modal-card-image {
  width: 100%;
  max-width: 250px;
  height: auto;
  margin-bottom: 1rem;
}

.card-description {
  margin-bottom: 1rem;
  font-size: 0.9rem;
}

.buy-button,
.tarot-room-button {
  background-color: black;
  color: var(--light-text);
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  font-family: var(--body-font);
  font-size: 0.9rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin: 0.5rem auto;
  transition: all 0.3s ease;
  width: 100%;
  max-width: 250px;
}

.buy-button:hover,
.tarot-room-button:hover {
  background-color: var(--lightning-yellow);
  box-shadow: 0 0 15px var(--lightning-yellow);
}

.tarot-room-button {
  background-color: var(--text-color);
}

.minting-info {
  margin-top: 3rem;
  background: rgba(10, 10, 11, 0.5);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  padding: 2rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.minting-info h2 {
    font-family: var(--title-font);
    font-size: 3rem;
    margin-bottom: 2rem;
    margin-top: 1rem;
    color: #ff36ab;
    text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3), 0 0 20px rgba(233, 69, 96, 0.5),
      0 0 40px rgba(233, 69, 96, 0.3);
    font-weight: 900;
    letter-spacing: -0.05em;
    line-height: 0.9;
    transform: skew(-5deg);
    transition: all 0.3s ease;
    text-wrap: balance;
}

.info-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.info-card {
  background: rgba(104, 103, 103, 0.05);
  border-radius: 15px;
  padding: 1.5rem;
  text-align: center;
  transition: all 0.3s ease;
}

.info-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(255, 54, 171, 0.3);
}

.info-icon {
  font-size: 2.5rem;
  color: var(--cyberpunk-pink);
  margin-bottom: 1rem;
}

.info-card h3 {
  color: var(--light-text);
  margin-bottom: 0.5rem;
}

.info-card p {
  color: var(--light-text);
  opacity: 0.8;
}

.buy-button.full-collection {
  background-color: black;
  width: 100%;
  max-width: 400px;
  margin: 1rem auto;
  font-size: 1rem;
  padding: 0.8rem 1.5rem;
}

.gamma-footer {
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.footer-left,
.footer-center,
.footer-right {
  display: flex;
  align-items: center;
  flex: 1;
}

.footer-left {
  justify-content: flex-start;
}

.footer-center {
  justify-content: center;
}

.footer-right {
  justify-content: flex-end;
}

.skull-logo {
  width: 10rem;
  height: auto;
}

.mystichat-text {
  font-family: var(--title-font);
  color: var(--cyberpunk-pink);
  font-size: 1.2rem;
  margin-left: 10px;
}

.gamma-logo {
  max-width: 10rem;
  height: auto;
}

.bittech-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--light-text);
  transition: all 0.3s ease;
}

.bittech-link:hover {
  opacity: 0.8;
}

.bittech-logo {
  width: 15rem;
  height: auto;
}

@media (max-width: 768px) {
  .gamma-footer {
    flex-direction: column;
    gap: 1.5rem;
  }

  .footer-left,
  .footer-center,
  .footer-right {
    justify-content: center;
  }

  .skull-logo,
  .gamma-logo,
  .bittech-logo {
    width: 8rem;
  }
  .card-modal {
    padding-right: calc(2rem + 8px); /* 2rem del padding original + 8px del ancho del scrollbar */
  }
  .card-modal {
    padding-right: 2rem;
  }
}

@media (max-width: 480px) {
  .skull-logo,
  .gamma-logo,
  .bittech-logo {
    width: 6rem;
  }

  .mystichat-text {
    font-size: 1rem;
  }
  .ordinals-description {
    font-size: 0.9rem;
  }
}

@media (max-width: 1024px) {
  .card-grid {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }

  .info-cards {
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  }
}

@media (max-width: 768px) {
  .ordinals-container {
    padding: 1.5rem;
  }

  h1 {
    font-size: 2.2rem;
  }

  .card-grid {
    gap: 1.5rem;
  }

  .gamma-footer {
    flex-direction: column;
    gap: 1.5rem;
  }

  .footer-left,
  .footer-center,
  .footer-right {
    justify-content: center;
  }

  .skull-logo,
  .gamma-logo,
  .bittech-logo {
    width: 8rem;
  }
}

@media (max-width: 480px) {
  .ordinals-container {
    padding: 1rem;
  }

  h1 {
    font-size: 3rem;
  }

  .card-grid {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 1rem;
  }

  .card-modal {
    padding: 1.5rem;
    width: 95%;
  }

  .modal-card-image {
    max-width: 200px;
  }

  .card-description {
    font-size: 0.8rem;
  }

  .buy-button,
  .tarot-room-button {
    font-size: 0.8rem;
    padding: 0.4rem 0.8rem;
  }

  .minting-info {
    padding: 1.5rem;
  }

  .info-cards {
    grid-template-columns: 1fr;
  }

  .buy-button.full-collection,
  .tarot-room-button {
    font-size: 0.9rem;
    padding: 0.7rem 1.2rem;
  }

  .skull-logo, .gamma-logo {
    width: 6rem;
  }
  
  .bittech-logo {
    width: 8rem;
  }
  
}