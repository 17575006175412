@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700;900&family=Open+Sans:wght@400;600&display=swap');

:root {
    --primary-color: #080808;
    --secondary-color: #0f0f10;
    --accent-color: #0f3460;
    --text-color: #ff36ab;
    --light-text: #f1f1f1;
    --background: #0f0f0f;
    --title-font: 'Montserrat', sans-serif;
    --body-font: 'Open Sans', sans-serif;
    --cyberpunk-pink: #adff02;
    --cyberpunk-purple: #8f00ff;
    --cyberpunk-blue: #ff006d;
    --cyberpunk-cyan: #ff7d00;
    --cyberpunk-dark: #ffff00;
    --icon-size: 3rem;
    --icon-margin: 0.1rem 0;
}

.dashboard {
    height: 100vh;
    width: 100vw;
    color: var(--light-text);
    padding: 0.12rem 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow-y: auto;
    overflow-x: hidden;
    box-sizing: border-box;
    font-family: var(--body-font);
}

.dashboard h1 {
    font-family: var(--title-font);
    font-size: 2.1rem;
    margin-bottom: 1.1rem;
    margin-top: 1.4rem;
    color: var(--text-color);
    text-shadow: 
        2px 2px 4px rgba(0,0,0,0.3),
        0 0 10px rgba(233, 69, 96, 0.5),
        0 0 20px rgba(233, 69, 96, 0.3);
    font-weight: 900;
    letter-spacing: -0.05em;
    line-height: 0.9;
    transform: skew(-5deg);
    transition: all 0.3s ease;
    text-align: center;
    width: 100%;
    padding: 0 1rem;
    margin-left: auto;
    margin-right: auto;
}

.dashboard-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    width: 100%;
    max-width: 1200px;
    padding: 1rem;
    margin: 0 auto;
}

.dashboard-card {
    background: rgba(15, 15, 16, 0.9);
    border-radius: 0.5rem;
    padding: 1rem;
    text-align: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 30;
}

.dashboard-card:hover, .mystic-status-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.card-icon, .moon-icon, .clock-icon, .crown-icon, 
.tarot-icon, .dream-icon, .logout-icon, .quote-icon {
    font-size: var(--icon-size);
    margin-bottom: 0.5rem;
}

.dashboard-card h2 {
    font-size: 1.2rem;
    color: var(--light-text);
    margin-bottom: 0.5rem;
}

.dashboard-card p {
    font-size: 0.95rem;
    color: var(--light-text);
    text-wrap: balance;
}

.quote-icon {
    font-size: 1.2rem;
    color: var(--text-color);
    margin-bottom: 0.1rem;
}

/* Estilos de los iconos específicos */
.card-icon { color: var(--cyberpunk-purple); }
.moon-icon { color: var(--cyberpunk-pink); }
.clock-icon { color: var(--cyberpunk-blue); }
.crown-icon { color: var(--cyberpunk-cyan); }
.tarot-icon { color: #e50cf3; }
.dream-icon { color: #4361ee; }
.logout-icon { color: #e63946; }

/* Estilos del botón de navegación */
.nav-button {
    text-decoration: none;
    color: var(--light-text);
    font-size: 1rem;
    transition: transform 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
}

.nav-button:hover {
    transform: scale(1.05);
}

.carousel-card {
    height: auto;
    min-height: 200px;
    overflow: hidden;
}

.carousel-card .whats-hot-carousel {
    width: 100%;
    height: 100%;
    min-height: 200px;
}

.carousel-card .carousel-cell img {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 0.5rem;
}

.carousel-card .carousel-cell h2,
.carousel-card .carousel-cell p {
    color: var(--light-text);
    margin: 0.5rem 0;
}

.carousel-card .carousel-cell h2 {
    font-size: 1rem;
}

.carousel-card .carousel-cell p {
    font-size: 0.8rem;
}

.subscription-status {
    font-weight: 600;
    font-size: 1.1rem;
    padding: 0.2rem 0.5rem;
    border-radius: 0.3rem;
}

.subscription-status-free { color: #FFD700; }
.subscription-status-monthly { color: #32CD32; }
.subscription-status-annual { color: #FFA500; }

.mystic-status-card {
    display: grid;
    grid-template-rows: auto 1fr;
    gap: 1rem;
    padding: 1.0rem;
    background: rgba(15, 15, 16, 0.6);
    border-radius: 1rem;
    z-index: 30;
}

@keyframes mystic-buy-text-float {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-5px); }
}

.mystic-buy-text {
    font-family: var(--title-font);
    font-size: 1.5rem;
    font-weight: bold;
    cursor: pointer;
    background-image: linear-gradient(45deg, #ff00ff, #ff69b4, #9ef01a);
    background-size: 200% auto;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: all 0.3s ease;
    padding: 0px 10px;
    border-radius: 5px;
    text-shadow: 2px 2px 4px rgba(0,0,0,0.1);
    margin-top: 0rem;
    animation: mystic-buy-text-float 3s ease-in-out infinite;
}

.mystic-buy-text:hover {
    background-position: right center;
    transform: scale(1.05);
    text-shadow: 3px 3px 6px rgba(0,0,0,0.2);
}

.mystic-buy-text:active {
    transform: scale(0.98);
}

.mystic-status-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
}

.mystic-status-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.mystic-status-icon {
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
}

.mystic-status-icon-coins { color: #9ef01a; }
.mystic-status-icon-crown { color: #ff36ab; }

.mystic-status-text-container {
    text-align: center;
}

.mystic-status-label {
    font-size: 0.9rem;
    color: #ffffff;
    opacity: 0.8;
    margin: 0;
}

.mystic-status-value {
    font-size: 1.4rem;
    font-weight: bold;
    margin: 0.2rem 0 0;
}

.mystic-status-value-coins { color: #ffd700; }
.mystic-status-value-level { color: #ff5c9a; }

/* Media Queries */
@media (max-width: 1200px) {
    .dashboard-grid {
        grid-template-columns: repeat(3, 1fr);
        gap: 0.8rem;
        padding: 0.8rem;
    }
    .dashboard-card { height: 140px; }
}

@media (min-width: 993px) and (max-width: 1024px) {
    .dashboard-grid {
        grid-template-columns: repeat(3, 1fr);
        gap: 1rem;
        padding: 1rem 1.5rem;
    }
    .dashboard-card { height: 135px; }
    .card-icon, .moon-icon, .clock-icon, .crown-icon, 
    .tarot-icon, .dream-icon, .logout-icon, .quote-icon { font-size: 2.7rem; }
    .dashboard-card h2 { font-size: 1.05rem; }
    .dashboard-card p { font-size: 0.75rem; }
}

@media (min-width: 769px) and (max-width: 992px) {
    .dashboard-grid {
        grid-template-columns: repeat(2, 1fr);
        gap: 1.2rem;
        padding: 1.2rem 2rem;
    }
    .dashboard-card { height: 145px; }
    .card-icon, .moon-icon, .clock-icon, .crown-icon, 
    .tarot-icon, .dream-icon, .logout-icon, .quote-icon { font-size: 2.8rem; }
    .dashboard-card h2 { font-size: 1.15rem; }
    .dashboard-card p { font-size: 0.8rem; }
    .mystic-status-card { padding: 1.2rem; }
    .mystic-buy-text { font-size: 1.6rem; }
    .mystic-status-icon { font-size: 2.2rem; }
    .mystic-status-label { font-size: 0.85rem; }
    .mystic-status-value { font-size: 1.3rem; }
}

@media (min-width: 577px) and (max-width: 768px) {
    .dashboard-grid {
        grid-template-columns: repeat(2, 1fr);
        gap: 0.8rem;
        padding: 0.8rem;
    }
    .dashboard-card { height: 120px; }
    .card-icon, .moon-icon, .clock-icon, .crown-icon, 
    .tarot-icon, .dream-icon, .logout-icon, .quote-icon { font-size: 2.5rem; }
    .dashboard-card h2 { font-size: 1rem; }
    .dashboard-card p { font-size: 0.7rem; }
    .mystic-status-title { font-size: 1.2rem; }
    .mystic-status-icon { font-size: 1.9rem; }
    .mystic-status-label { font-size: 0.7rem; }
    .mystic-status-value { font-size: 1.1rem; }
    .mystic-buy-text { font-size: 1.4rem; }
}

@media (max-width: 576px) {
    .dashboard { padding: 0.12rem 0; }
    .dashboard h1 {
        font-size: 1.6rem;
        margin: 1.7rem 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .dashboard h1 span:first-child {
        margin-bottom: 0.5rem;
    }
    .dashboard-grid {
        grid-template-columns: 0.90fr;
        gap: 0.8rem;
        padding: 0.8rem;
        width: 95%;
        margin: 1.0 auto;
    }
    .dashboard-card {
        height: auto;
        min-height: 100px;
        padding: 1rem;
        width: 100%;
    }
    .card-icon, .moon-icon, .clock-icon, .crown-icon, 
    .tarot-icon, .dream-icon, .logout-icon, .quote-icon { font-size: 2.5rem; }
    .dashboard-card h2 {
        font-size: 1.1rem;
        margin-bottom: 0.4rem;
    }
    .dashboard-card p, .dashboard-card small { font-size: 0.9rem; }
    .mystic-status-card {
        width: 100%;
        padding: 1rem;
    }
    .carousel-card { width: 100%; }
    .subscription-status { font-size: 1rem; }
    .mystic-status-content { 
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
    }
    .mystic-status-icon { font-size: 2rem; }
    .mystic-status-label { font-size: 0.8rem; }
    .mystic-status-value { font-size: 1.2rem; }
    .mystic-buy-text { 
        font-size: 1.5rem;
        margin-bottom: 0.5rem;
    }
}

@media (max-width: 375px) {
    .dashboard h1 { font-size: 1.4rem; }
    .dashboard-grid { width: 95%; }
    .dashboard-card {
        min-height: 90px;
        padding: 0.8rem;
    }
    .card-icon, .moon-icon, .clock-icon, .crown-icon, 
    .tarot-icon, .dream-icon, .logout-icon, .quote-icon { font-size: 2.2rem; }
    .dashboard-card h2 { font-size: 1rem; }
    .dashboard-card p, .dashboard-card small { font-size: 0.8rem; }
    .subscription-status { font-size: 0.9rem; }
    .mystic-status-icon { font-size: 1.8rem; }
    .mystic-status-label { font-size: 0.7rem; }
    .mystic-status-value { font-size: 1.1rem; }
    .mystic-buy-text { font-size: 1.3rem; }
}

@media (hover: none) {
    .dashboard-card:hover, .mystic-status-card:hover {
        transform: none;
        box-shadow: none;
    }
}

.moon-phase-spanish {
    font-size: 0.2em;
    color: #666;
    margin-top: -10px;
}