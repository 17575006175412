.tarot-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(8, 8, 8, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.tarot-modal-content {
  background: rgba(15, 15, 16, 0.8);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  padding: 2rem;
  width: 90%;
  max-width: 800px;
  box-shadow: 0 8px 32px 0 rgba(128, 128, 130, 0.37);
}

.tarot-modal-content h2 {
  font-family: var(--title-font);
  font-size: 2rem;
  margin-bottom: 2rem;
  margin-top: 0rem;
  color: #ff36ab;
  text-shadow: 
    4px 4px 8px rgba(0,0,0,0.3),
    0 0 20px rgba(233, 69, 96, 0.5),
    0 0 40px rgba(233, 69, 96, 0.3);
  font-weight: 900;
  letter-spacing: -0.05em;
  line-height: 0.9;
  transform: skew(-5deg);
  transition: all 0.3s ease;
}

.tarot-modal-options {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}

.option-card {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  border-radius: 15px;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.option-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle at center, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 70%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.option-card:hover::before {
  opacity: 1;
}

.ordinals {
  background-color: rgba(247, 147, 26, 0.2);
}

.tarot {
  background-color: rgba(255, 54, 171, 0.2);
}

.option-icon {
  font-size: 4rem;
  margin-bottom: 1rem;
}

.ordinals .option-icon {
  color: var(--bitcoin-orange);
}

.tarot .option-icon {
  color: var(--text-color);
}

.option-card h3 {
  font-family: var(--title-font);
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: var(--light-text);
}

.option-card p {
  font-family: var(--body-font);
  color: var(--light-text);
  text-align: center;
}

.gamma-logo-1 {
  max-width: 100px;
  height: auto;
  margin-top: 1rem;
}

@media (max-width: 768px) {
  .tarot-modal-content {
    width: 95%;
    padding: 1.5rem;
    max-height: 90vh;
    overflow-y: auto;
  }
  
  .tarot-modal-options {
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .option-card {
    padding: 1.5rem;
  }
}

@media (max-width: 480px) {
  .tarot-modal-overlay {
    align-items: flex-start;
    padding: 1rem;
  }

  .tarot-modal-content {
    width: 100%;
    height: auto;
    max-height: 95vh;
    border-radius: 15px;
    padding: 1.5rem;
    overflow-y: auto;
  }
  
  .tarot-modal-content h2 {
    font-size: 1.8rem;
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
  }
  
  .tarot-modal-options {
    gap: 1rem;
  }

  .option-card {
    padding: 1rem;
  }
  
  .option-icon {
    font-size: 3rem;
  }
  
  .option-card h3 {
    font-size: 1.2rem;
  }

  .option-card p {
    font-size: 0.9rem;
  }

  .option-card.tarot {
    order: 1;
  }

  .option-card.ordinals {
    order: 2;
  }

  .gamma-logo-1 {
    max-width: 80px;
    margin-top: 0.5rem;
  }
}