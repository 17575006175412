@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700;900&family=Open+Sans:wght@400;600&display=swap');

:root {
  --primary-color: #080808;
  --secondary-color: #0f0f10;
  --text-color: #ff36ab;
  --light-text: #f1f1f1;
  --background: #0f0f0f;
  --title-font: 'Montserrat', sans-serif;
  --body-font: 'Open Sans', sans-serif;
}

.signin-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: var(--body-font);
}

.signin-container {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-color);
  padding: 20px;
}

.signin-box {
  display: flex;
  width: 90%;
  max-width: 1200px;
  height: 80vh;
  max-height: 700px;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  border: 3px solid whitesmoke;
}

.left-panel {
  flex: 1;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.logo-skull-2 {
  width: 12rem;
  height: auto;
  margin-bottom: 0px;
}

.text-intro-signin {
  text-align: left;
  margin-bottom: 40px;
}

.text-intro-signin h2 {
  font-family: var(--title-font);
  font-size: 2.5rem;
  color: var(--text-color);
  margin-bottom: 10px;
  font-weight: 700;

}

.text-intro-signin p {
  font-family: var(--body-font);
  font-size: 1.1rem;
  color: var(--light-text);
  line-height: 1.6;
  text-wrap: balance;
}

.signInButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--light-text);
  color: var(--primary-color);
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  font-family: var(--body-font);
  font-weight: 600;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
  max-width: 300px;
  margin: 0 auto;
}

.signInButton:hover {
  background-color: var(--text-color);
  color: var(--light-text);
}

.google-logo {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.signInButton span {
  font-weight: bold;
}

.footer-text {
  font-size: 0.8rem;
  font-family: var(--body-font);
  color: var(--light-text);
  opacity: 0.7;
  margin-top: 20px;
  text-wrap: balance;
}

.right-panel {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.tarot-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: transparent;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.tarot-image.fade-in {
  opacity: 1;
}

.policy-links {
  margin-top: 10px;
  font-size: 0.8em;
  color: #adff02;
}

.policy-links a {
  color: #ff5c9a;
  text-decoration: none;
}

.policy-links a:hover {
  text-decoration: underline;
}

/* Breakpoints mejorados para dispositivos móviles */
@media (max-width: 1024px) {
  .signin-box {
    height: auto;
    max-height: none;
  }

  .left-panel {
    padding: 25px;
  }

  

  .text-intro-signin h2 {
    font-size: 2.2rem;
  }

  .text-intro-signin p {
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .signin-box {
    flex-direction: column;
    height: auto;
  }

  .left-panel {
    padding: 20px;
  }

  .logo-skull-2 {
    width: 140px;
  }

  .text-intro-signin {
    text-align: center;
    margin-bottom: 20px;
  }

  .text-intro-signin h2 {
    font-size: 2rem;
  }

  .right-panel {
    display: none;
  }
}

@media (max-width: 480px) {
  .signin-container {
    padding: 15px;
  }

  .signin-box {
    width: 95%;
    height: auto;
    min-height: 80vh;
  }

  .left-panel {
    padding: 15px;
    justify-content: space-around;
  }

  .text-intro-signin h2 {
    font-size: 2rem;
    margin-bottom: 0.5rem;
    margin-top: -1rem;
  }

  .text-intro-signin p {
    font-size: 0.9rem;
    margin-top: 1rem;
    text-wrap: balance;
    margin-bottom: 0rem;
  }

  .signInButton {
    font-size: 0.9rem;
    padding: 12px 20px;
    margin: 1rem auto;
    width: 80%;
    max-width: none;
  }

  .google-logo {
    width: 20px;
    height: 20px;
  }

  .footer-text,
  .policy-links {
    font-size: 0.7rem;
    text-align: center;
  }
}

/* Estilos específicos para iPad */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (orientation: portrait) {
  .policy-links {
    margin-top: 15px;
    font-size: 0.9em;
  }
}

@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (orientation: landscape) {
  .policy-links {
    margin-top: 12px;
    font-size: 0.85em;
  }
}

@media only screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1366px)
  and (orientation: portrait) {
  .policy-links {
    margin-top: 20px;
    font-size: 1em;
  }
}

@media only screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1366px)
  and (orientation: landscape) {
  .policy-links {
    margin-top: 18px;
    font-size: 0.95em;
  }
}