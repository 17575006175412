/* LegalDocuments.css */

.legal-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px;
  font-family: 'Roboto', Arial, sans-serif;
  line-height: 1.6;
  color: #e0e0e0;
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 15px;
  box-shadow: 0 0 20px rgba(173, 255, 2, 0.2);
}

.legal-heading {
  color: #adff02;
  border-bottom: 2px solid #adff02;
  padding-bottom: 15px;
  margin-bottom: 30px;
  font-size: 2.0em;
  text-align: center;
  text-shadow: 0 0 10px rgba(173, 255, 2, 0.5);
}

.legal-subheading {
  color: #ff5c9a;
  margin-top: 40px;
  margin-bottom: 20px;
  font-size: 1.8em;
  text-shadow: 0 0 5px rgba(255, 92, 154, 0.5);
}

.legal-paragraph {
  margin-bottom: 20px;
  font-size: 1.1em;
  text-align: justify;
}

.legal-list {
  margin-bottom: 20px;
  padding-left: 25px;
}

.legal-list-item {
  margin-bottom: 15px;
  position: relative;
}

.legal-list-item::before {
  content: '•';
  color: #adff02;
  position: absolute;
  left: -20px;
}

.legal-date {
  font-style: italic;
  color: #adff02;
  text-align: center;
  margin-bottom: 30px;
  font-size: 1.1em;
}

.legal-emphasis {
  font-weight: bold;
  color: #ff5c9a;
}

.legal-contact {
  background-color: rgba(255, 92, 154, 0.1);
  padding: 25px;
  border-radius: 10px;
  margin-top: 40px;
  border: 1px solid rgba(255, 92, 154, 0.3);
}

.legal-contact h3 {
  color: #adff02;
  margin-bottom: 15px;
  font-size: 1.5em;
}

.legal-contact p {
  margin: 10px 0;
}

.legal-footer {
  text-align: center;
  margin-top: 50px;
  font-weight: bold;
  color: #adff02;
  font-size: 1.1em;
  text-shadow: 0 0 5px rgba(173, 255, 2, 0.5);
}

/* Tablet Styles */
@media (max-width: 768px) {
  .legal-container {
    padding: 30px;
    margin: 0 20px;
  }

  .legal-heading {
    font-size: 2.2em;
  }

  .legal-subheading {
    font-size: 1.6em;
  }

  .legal-paragraph, .legal-list-item {
    font-size: 1.05em;
  }

  .legal-contact {
    padding: 20px;
  }
}

/* Mobile Styles */
@media (max-width: 480px) {
  .legal-container {
    padding: 20px;
    margin: 0 10px;
    border-radius: 10px;
  }

  .legal-heading {
    font-size: 1.8em;
  }

  .legal-subheading {
    font-size: 1.4em;
    margin-top: 30px;
  }

  .legal-paragraph, .legal-list-item {
    font-size: 1em;
  }

  .legal-list {
    padding-left: 20px;
  }

  .legal-contact {
    padding: 15px;
  }

  .legal-contact h3 {
    font-size: 1.3em;
  }

  .legal-footer {
    font-size: 1em;
  }
}