@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700;900&family=Open+Sans:wght@400;600&display=swap');

:root {
  --primary-color: #080808;
  --secondary-color: #0f0f10;
  --accent-color: #0f3460;
  --text-color: #ff36ab;
  --light-text: #f1f1f1;
  --background: #0f0f0f;
  --title-font: 'Montserrat', sans-serif;
  --body-font: 'Open Sans', sans-serif;
}

.tarot-room {
  height: 100vh;
  width: 100vw;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:flex-start;
  position: relative;
  overflow: hidden;
  padding: 0.9rem;
  box-sizing: border-box;
  font-family: var(--body-font);
  padding-top: 80px; /* Add space for the fixed header */

}

.tarot-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  z-index: 10;
  background: transparent;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}

.back-link {
  flex: 1;
  display: flex;
  justify-content: flex-start;
}

.tarot-title-container {
  flex: 2;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header-buttons {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
}

.nft-button, .tarot-tutorial-button {
  background: none;
  border: none;
  color: var(--text-color);
  font-size: 2rem;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
}

.nft-button::before, .tarot-tutorial-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--text-color);
  opacity: 0;
  transition: opacity 0.3s ease;
  border-radius: 50%;
}

.nft-button:hover, .tarot-tutorial-button:hover {
  color: var(--primary-color);
  transform: translateY(-2px);
}

.nft-button:hover::before, .tarot-tutorial-button:hover::before {
  opacity: 1;
}

.nft-button:active, .tarot-tutorial-button:active {
  transform: translateY(0);
}

.nft-button svg, .tarot-tutorial-button svg {
  position: relative;
  z-index: 1;
}



@media (max-width: 480px) {
  .tarot-header {
    padding: 0.5rem;
  }

  .tarot-title {
    font-size: 1.5rem;
  }

  .nft-button, .tarot-tutorial-button {
    font-size: 1.5rem;
  }
}

.tarot-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0rem;
}

.tarot-title {
  font-family: var(--title-font);
  font-size: 2.5rem;
  color: var(--text-color);
  text-shadow: 
    2px 2px 4px rgba(0,0,0,0.3),
    0 0 10px rgba(233, 69, 96, 0.5),
    0 0 20px rgba(233, 69, 96, 0.3);
  font-weight: 900;
  letter-spacing: -0.05em;
  line-height: 1;
  transform: skew(-5deg);
  transition: all 0.3s ease;
  margin: 0 0 0.5rem 0;
  text-align: center;
  margin-top: 1rem;
  
}

.mysticoins-counter {
  font-family: var(--body-font);
  font-size: 1rem;
  color: var(--text-color);
  background-color: rgba(0, 0, 0, 0.5);
  padding: 0.3rem 0.8rem;
  border-radius: 15px;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
  margin-top: 0.5rem;
}

.back-link, .tarot-tutorial-button {
  color: var(--text-color);
  text-decoration: none;
  font-size: 1.3rem;
  transition: all 0.3s ease;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
}



.tarot-form {
  width: 90%;
  max-width: 700px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  padding: 1.8rem;
  box-shadow: 0 8px 32px rgba(248, 134, 248, 0.2);
  z-index: 3;
  margin-top: 10rem;
}

.form-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.spread-select-container {
  position: relative;
  width: 100%;
}

.spread-select {
  width: 100%;
  height: 3.2rem;
  padding: 0 3.2rem 0 1rem;
  border: none;
  border-radius: 22px;
  background: rgba(0, 0, 0, 0.5);
  color: #ffffff;
  font-size: 1rem;
  font-family: var(--body-font);
  transition: all 0.3s ease;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  margin-bottom: 1rem;
}

.spread-select:focus {
  outline: none;
  box-shadow: 0 0 0 2px var(--text-color);
}

.spread-info-icon {
  position: absolute;
  right: 1rem;
  top: 38%;
  transform: translateY(-50%);
  color: #fdc500;
  cursor: pointer;
  font-size: 1.1rem;
}

.spread-info {
  position: absolute;
  top: calc(100% + 0.0rem);
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 1.7);
  padding: 1rem;
  border-radius: 10px;
  font-size: 0.85rem;
  z-index: 10;
}

.question-input {
  width: 100%;
  height: 3.2rem;
  padding: 0 1rem; /* Añadido padding horizontal */
  border: none;
  border-radius: 22px;
  background: rgba(0, 0, 0, 0.5);
  color: #ffffff;
  font-size: 1rem;
  margin-bottom: 1rem;
  font-family: var(--body-font);
  transition: all 0.3s ease;
  box-sizing: border-box; /* Asegura que el padding no afecte el ancho total */
  overflow: hidden; /* Oculta el texto que se desborda */
  text-overflow: ellipsis; /* Muestra puntos suspensivos para el texto que se desborda */
  white-space: nowrap; /* Evita que el texto se envuelva */
}

.question-input:focus {
  outline: none;
  box-shadow: 0 0 0 2px var(--text-color);
}

/* Estilo para el placeholder */
.question-input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

@keyframes highlightSpread {
  0%, 100% { box-shadow: 0 0 5px rgba(255, 54, 171, 0.5); }
  50% { box-shadow: 0 0 15px rgba(255, 54, 171, 0.8); }
}

.spread-select.hint {
  animation: highlightSpread 2s infinite;
  color: #ff36ab; /* Color destacado para el hint */
}

.spread-select option {
  color: initial; /* Restaura el color original para las opciones */
}
.submit-button {
  width: 100%;
  height: 3.2rem;
  border: none;
  border-radius: 22px;
  background: #5a189a;
  color: #ffffff;
  font-size: 1rem;
  font-family: var(--body-font);
  cursor: pointer;
  transition: all 0.3s ease;
}

.submit-button:hover {
  background: var(--text-color);
}

.reading-container {
  flex-grow: 1;
  margin-bottom: 1rem;
  padding: 18px;
  overflow-y: auto;
  max-height: 75vh;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 2.5rem;
  margin-top: 5rem;
}

.cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2.5rem;
  margin: 0.3rem 0 1rem;
  background: rgba(19, 19, 19, 0.3);
  padding: 1.3rem;
  border-radius: 1.8rem;
}

.card {
  width: 18rem;
  perspective: 1000px;
  transition: transform 0.6s;
}

.card img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.card-name {
  text-align: center;
  margin-top: 0.5rem;
  font-size: 1.2rem;
  font-family: var(--title-font);
  color: #adff02;
}

.interpretation {
  font-size: 1rem;
  line-height: 1.5;
  color: #f0f0f0;
  text-shadow: 0 0 5px rgba(44, 43, 43, 0.5);
  padding: 1.8rem;
  background: rgba(14, 14, 14, 0.5);
  border-radius: 15px;
  margin: 1rem 0;
  word-break: break-word;
  white-space: pre-wrap;
}

.reset-button {
  background: linear-gradient(135deg, #adff02, #8ecf00);
  color: #000000;
  cursor: pointer;
  font-weight: bold;
  font-family: var(--body-font);
  padding: 8px 16px;
  border: none;
  border-radius: 8px;
  font-size: 0.9rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.reset-button:hover {
  background: linear-gradient(135deg, #8ecf00, #adff02);
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.reset-button:active {
  transform: translateY(1px);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
}

.bot-response-text {
  font-family: 'Roboto', sans-serif;
  font-size: 1.2rem;
  line-height: 1.6;
  color: #f5f5f5;
  background-color: rgba(14, 14, 14, 0.8);
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  letter-spacing: 0.015em;

}

.bot-response-paragraph {
  margin-bottom: 1.2rem;
  font-weight: 300;
  color: #e0e0e0;
}

.bot-response-text strong {
  font-weight: 700;
  color: #adff02;
}

.bot-response-text em {
  font-style: italic;
  color: #ff9800;
}

.bot-response-text a {
  color: #00bcd4;
  text-decoration: none;
  border-bottom: 1px solid #00bcd4;
  transition: all 0.3s ease;
}

.bot-response-text a:hover {
  color: #80deea;
  border-bottom-color: #80deea;
}

/* Responsive styles */
@media (max-width: 1024px) {
  .tarot-title {
    font-size: 2rem;
  }

  .tarot-form {
    width: 95%;
    padding: 1.5rem;
  }

  .bot-response-text {
    font-size: 1rem;
    padding: 1.5rem;
    max-width: 90%;
  }
}

@media (max-width: 768px) {
  .tarot-header {
    flex-wrap: nowrap;
    padding: 0.8rem;
  }

  .header-buttons {
    gap: 0.5rem;
  }

  .tarot-title {
    font-size: 1.8rem;
  }

  .back-link, .tarot-tutorial-button {
    font-size: 1.1rem;
    width: 40px;
    height: 40px;
  }

  .tarot-form {
    width: 95%;
    padding: 1rem;
  }

  .back-link, .tarot-tutorial-button, .nft-button {
    font-size: 1.5rem;
    width: 40px;
    height: 40px;
  }

  .spread-select,
  .question-input,
  .submit-button {
    height: 3rem;
    font-size: 0.95rem;
  }

  .bot-response-text {
    font-size: 0.95rem;
    padding: 1.2rem;
  }
}

@media (max-width: 480px) {
  .tarot-header {
    padding: 0.6rem 0.8rem;
  }

  .back-link, .nft-button, .tarot-tutorial-button {
    font-size: 1.3rem;
    width: 35px;
    height: 35px;
  }

  .tarot-title {
    font-size: 1.9rem;
    padding: 2%;
  }

  .back-link, .tarot-tutorial-button {
    font-size: 1rem;
    width: 35px;
    height: 35px;
  }

  .back-link {
    left: 0.5rem;
  }

  .tarot-tutorial-button {
    right: 0.5rem;
  }

  .tarot-form {
    width: 100%;
    padding: 0.8rem;
  }

  .spread-select,
  .submit-button {
    height: 2.8rem;
    font-size: 0.9rem;
  }

  .bot-response-text {
    font-size: 1.1rem;
    padding: 1rem;
    line-height: 1.5;
    text-wrap: balance;
  }

  .bot-response-paragraph {
    margin-bottom: 1rem;
  }
}

@media (max-height: 600px) {
  .tarot-form {
    margin-top: 1rem;
    max-height: 80vh;
  }
}

@media (max-width: 480px) {
  .question-input {
    font-size: 1rem;
    padding: 0 0.8rem;
  }
}

.tarot-card-image {
  opacity: 0;
  transition: opacity 0.5s ease-in;
}

.tarot-card-image.visible {
  opacity: 1;
}
