@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700;900&family=Open+Sans:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');

:root {
  --primary-color: #080808;
  --secondary-color: #0f0f10;
  --accent-color: #0f3460;
  --text-color: #ff36ab;
  --light-text: #f1f1f1;
  --background: #0f0f0f;
  --title-font: 'Montserrat', sans-serif;
  --body-font: 'Open Sans', sans-serif;
}

.dream-room {
  height: 100vh;
  width: 100vw;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  overflow: hidden;
  padding: 0.9rem;
  box-sizing: border-box;
  font-family: var(--body-font);
  padding-top: 80px;
}

.dream-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  z-index: 10;
  background: transparent;
  
}

.back-link {
  flex: 1;
  display: flex;
  justify-content: flex-start;
}

.dream-title-container {
  flex: 2;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dream-title {
  font-family: var(--title-font);
  font-size: 2.5rem;
  color: var(--text-color);
  text-shadow: 
    2px 2px 4px rgba(0,0,0,0.3),
    0 0 10px rgba(233, 69, 96, 0.5),
    0 0 20px rgba(233, 69, 96, 0.3);
  font-weight: 900;
  letter-spacing: -0.05em;
  line-height: 1;
  transform: skew(-5deg);
  transition: all 0.3s ease;
  margin: 0;
}

.mysticoins-counter-dream {
  font-family: var(--body-font);
  font-size: 1rem;
  color: var(--text-color);
  background-color: rgba(0, 0, 0, 0.5);
  padding: 0.3rem 0.8rem;
  border-radius: 15px;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
  margin-top: 0.5rem;
}

.header-buttons {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
}

.dream-tutorial-button {
  color: var(--text-color);
  text-decoration: none;
  font-size: 1.3rem;
  transition: all 0.3s ease;
  background: none;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.dream-tutorial-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--text-color);
  opacity: 0;
  transition: opacity 0.3s ease;
  border-radius: 50%;
}

.dream-tutorial-button:hover {
  color: var(--primary-color);
  transform: translateY(-2px);
}

.dream-tutorial-button:hover::before {
  opacity: 1;
}

.dream-tutorial-button:active {
  transform: translateY(0);
}

.dream-tutorial-button svg {
  position: relative;
  z-index: 1;
}

.back-link {
  color: var(--text-color);
  text-decoration: none;
  font-size: 1.3rem;
  transition: all 0.3s ease; 
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
}


.mysticoins-counter-dream {
  font-family: var(--body-font);
  font-size: 1rem;
  color: var(--text-color);
  background-color: rgba(0, 0, 0, 0.5);
  padding: 0.3rem 0.8rem;
  border-radius: 15px;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
}

.dream-interface {
  width: 81%;
  max-width: 720px;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
  border-radius: 18px;
  padding: 1.8rem;
  box-shadow: 0 7px 29px rgba(248, 134, 248, 0.2);
  border: 1.8px solid rgba(0, 0, 0, 0.3);
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 63vh;
  max-height: 630px;
  margin-top: 4.5rem;
  overflow: hidden;
}

.response-container {
  flex-grow: 1;
  margin-bottom: 0.9rem;
  padding: 18px;
  overflow-y: auto;
  max-height: 54vh;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 9px;
}

.bot-icon {
  font-size: 1.8rem;
  color: #fdc500;
  margin-bottom: 0.9rem;
}

.dream-bot-response-text {
  font-family: 'Lato', sans-serif;
  font-size: 1.1rem;
  line-height: 1.7;
  color: #e0e0e0;
  background-color: rgba(0, 0, 0, 0.9);
  padding: 1.5rem;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.4);
  margin-top: 1.5rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  letter-spacing: 0.02em;
  transition: all 0.3s ease;
}

.dream-bot-response-paragraph {
  margin-bottom: 1.2rem;
  font-weight: 300;
  color: #f5f5f5;
}

.dream-bot-response-text strong {
  font-weight: 700;
  color: #7fdbff;
}

.dream-bot-response-text em {
  font-style: italic;
  color: #ffd700;
}

.dream-bot-response-text a {
  color: #ff69b4;
  text-decoration: none;
  border-bottom: 1px solid #ff69b4;
  transition: all 0.3s ease;
}

.dream-bot-response-text a:hover {
  color: #ff1493;
  border-bottom-color: #ff1493;
}

.response-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}

.response-actions button {
  background: rgba(0, 0, 0, 0.0);
  border: none;
  color: #fdc500;
  font-size: 1.2rem;
  cursor: pointer;
  margin-left: 1rem;
  transition: all 0.3s ease;
  padding: 0.5rem;
  border-radius: 50%;
}

.response-actions button:hover {
  background-color: rgba(255, 92, 154, 0.2);
  transform: scale(1.1);
}

.input-form {
  display: flex;
  gap: 0.9rem;
  margin-top: 0.9rem;
}

.dream-input {
  flex-grow: 1;
  padding: 0.9rem 1.35rem;
  border: none;
  border-radius: 10.5px;
  background: rgba(0, 0, 0, 0.5);
  color: #ffffff;
  font-size: 0.99rem;
  font-family: var(--body-font);
  backdrop-filter: blur(5px);
  transition: all 0.3s ease;
}

.dream-input:focus {
  outline: none;
  box-shadow: 0 0 0 2px var(--text-color);
}

.dream-input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.send-button, .clear-button {
  background: #9f86c0;
  color: #000000;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 1.08rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.send-button:hover, .clear-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
}

.send-button:hover {
  background: rgba(255, 92, 154, 0.8);
}

.clear-button {
  background: #5a189a;
}

.clear-button:hover {
  background: rgba(192, 57, 43, 0.8);
}

#tsparticles {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0.3;
}

.background-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
  opacity: 0;
  transform: scale(1.05);
}

.background-image.active {
  opacity: 1;
  transform: scale(1);
}

.background-image.previous {
  opacity: 0;
  transform: scale(1.05);
}

.dream-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.error-message {
  color: #ff6b6b;
  text-align: center;
  margin-top: 1rem;
  font-weight: bold;
}

@media (max-width: 1024px) {
  .dream-title {
    font-size: 2rem;
  }

  .dream-interface {
    width: 95%;
    padding: 1.5rem;
  }

  .dream-bot-response-text {
    font-size: 1rem;
    padding: 1.3rem;
    max-width: 90%;
  }
}

@media (max-width: 768px) {
  .dream-header {
    flex-wrap: nowrap;
    padding: 0.8rem;
  }

  .dream-title-container {
    flex: 1;
    order: 0;
  }

  .dream-title {
    font-size: 1.8rem;
  }

  .back-link, .dream-tutorial-button {
    font-size: 1.5rem;
    width: 40px;
    height: 40px;
  }

  .header-buttons {
    gap: 0.5rem;
  }

  .dream-interface {
    width: 95%;
    padding: 1rem;
    margin-top: 4rem;
  }

  .input-form {
    gap: 0.5rem;
  }

  .dream-input {
    height: 2.5rem;
    font-size: 0.9rem;
    padding: 0.5rem 1rem;
  }

  .send-button,
  .clear-button {
    width: 2.5rem;
    height: 2.5rem;
    font-size: 0.9rem;
  }

  .response-container {
    max-height: 50vh;
  }

  .dream-bot-response-text {
    font-size: 0.95rem;
    padding: 1.2rem;
  }
}

@media (max-width: 480px) {
  .dream-header {
    padding: 0.6rem;
  }

  .dream-title {
    font-size: 2.1rem;
  }

  .back-link, .dream-tutorial-button {
    font-size: 1rem;
    width: 35px;
    height: 35px;
  }

  .dream-interface {
    width: 95%;
    padding: 0.8rem;
    margin-top: 3rem;
    height: 70vh;
    max-height: none;
  }

  .response-container {
    max-height: 50vh;
    padding: 10px;
  }

  .dream-input,
  .send-button,
  .clear-button {
    height: 2.8rem;
    font-size: 0.9rem;
  }

  .response-container {
    max-height: 45vh;
    padding: 12px;
  }

  .dream-bot-response-text {
    font-size: 1rem;
    padding: 0.8rem;
    line-height: 1.5;
  }

  .dream-bot-response-paragraph {
    margin-bottom: 0.8rem;
  }

  .input-form {
    gap: 0.4rem;
    margin-top: 0.6rem;
  }

  .dream-input {
    height: 2.5rem;
    font-size: 0.85rem;
    padding: 0.4rem 0.8rem;
  }

  .send-button,
  .clear-button {
    width: 2.5rem;
    height: 2.5rem;
    font-size: 0.85rem;
  }

  .bot-icon {
    font-size: 1.5rem;
    margin-bottom: 0.6rem;
  }

  .mysticoins-counter-dream {
    font-size: 0.9rem;
  }
}

@media (max-height: 600px) {
  .dream-interface {
    margin-top: 3rem;
    height: 65vh;
  }

  .response-container {
    max-height: 45vh;
  }
}


