.whats-hot-carousel {
    width: 100%;
    height: 100%;
    min-height: 250px;
}

.carousel-card {
    height: 300px;
    overflow: hidden;
}

/* Ajusta el espaciado entre las celdas del carrusel */
.carousel-cell {
    width: 100%; /* Asegura que cada celda ocupe todo el ancho */
    padding: 0 10px; /* Añade un poco de padding horizontal */
    box-sizing: border-box;
}

.carousel-cell img {
    width: 45%; /* Reducimos un poco más el ancho */
    height: 120px; /* Aumentamos un poco la altura */
    object-fit: cover;
    object-position: center 70%; /* Ajustamos para mostrar más de la parte superior */
    border-radius: 0.5rem;
    margin-bottom: 0.5rem;
    margin-left: auto; /* Centramos la imagen */
    margin-right: auto; /* Centramos la imagen */
    display: block; /* Aseguramos que se comporte como bloque */
}

.carousel-cell h2 {
    font-size: 1.1rem;
    margin-bottom: 0.3rem;
    color: var(--text-color);
}

.carousel-cell p {
    font-size: 0.9rem;
    color: var(--light-text);
    margin-bottom: 0.5rem;
}

/* Estilos para los botones de navegación de Flickity */
.flickity-button {
    background: rgba(255, 255, 255, 0.7);
    transition: background 0.3s ease;
}

.flickity-button:hover {
    background: rgba(255, 255, 255, 0.9);
}

/* Estilos para los puntos de paginación de Flickity */
.flickity-page-dots {
    bottom: -25px;
}

.flickity-page-dots .dot {
    width: 8px;
    height: 8px;
    opacity: 0.5;
    background: var(--light-text);
}

.flickity-page-dots .dot.is-selected {
    opacity: 1;
    background: var(--text-color);
}

/* Media queries para responsividad */
@media (max-width: 992px) {
    .carousel-card {
        height: 250px;
    }

    .carousel-cell img {
        width: 50%;
        height: 100px;
    }
}

@media (max-width: 768px) {
    .whats-hot-carousel {
        min-height: 200px;
    }

    .carousel-card {
        height: 220px;
    }

    .carousel-cell img {
        width: 60%;
        height: 90px;
    }

    .carousel-cell h2 {
        font-size: 1rem;
    }

    .carousel-cell p {
        font-size: 0.8rem;
    }
}

@media (max-width: 576px) {
    .whats-hot-carousel {
        min-height: 180px;
    }

    .carousel-card {
        height: 200px;
    }

    .carousel-cell img {
        width: 70%;
        height: 80px;
    }

    .carousel-cell h2 {
        font-size: 0.9rem;
    }

    .carousel-cell p {
        font-size: 0.7rem;
    }
}
