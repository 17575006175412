.starry-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 0;
}

.star {
  position: absolute;
  background-color: #ffffff;
  border-radius: 50%;
  opacity: 0;
  animation: twinkle var(--duration, 3s) infinite ease-in-out;
}

@keyframes twinkle {
  0%, 100% { opacity: 0; }
  50% { opacity: 0.5; } /* Reducida la opacidad máxima para un efecto más sutil */
}

/* Variaciones de estrellas para un efecto más natural */
.star:nth-child(3n) {
  background-color: #ffe6a7; /* Tono amarillo suave */
}

.star:nth-child(4n) {
  background-color: #c6d7eb; /* Tono azul suave */
}

/* Ajuste de tamaños de estrellas */
.star.small {
  width: 1px;
  height: 1px;
}

.star.medium {
  width: 2px;
  height: 2px;
}

.star.large {
  width: 3px;
  height: 3px;
}