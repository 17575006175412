@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700;900&family=Open+Sans:wght@400;600&display=swap');

:root {
  --primary-color: #080808;
  --secondary-color: #0f0f10;
  --accent-color: #18191c;
  --text-color: #ff36ab;
  --light-text: #f1f1f1;
  --background: #0f0f0f;
  --title-font: 'Montserrat', sans-serif;
  --body-font: 'Open Sans', sans-serif;
  --cyberpunk-pink: #adff02;
  --bitcoin-orange: #f7931a;
  --lightning-yellow: #ffd700;
}

.buymysticoins-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.buymysticoins-modal-content {
  backdrop-filter: blur(13.5px);
  color: var(--light-text);
  padding: 1.5rem;
  border-radius: 15px;
  width: 90%;
  max-width: 1100px;
  max-height: 80vh;
  overflow-y: auto;
}

.buymysticoins-modal-message {
  color: #f5f5f5;
    font-family: Open Sans, sans-serif;
    font-family: var(--body-font);
    font-size: 1.05rem;
    line-height: 1.65;
    margin: 1.1rem auto 1.6rem;
    max-width: 825px;
    padding: 0 1.1rem;
    text-align: center;
}

.buymysticoins-modal-title {
  font-family: var(--title-font);
  text-align: center;
  color: var(--text-color);
  margin-bottom: 1rem;
  margin-top: -0.5rem;
  font-size: 2.2rem;
  font-weight: 700;
}

.buymysticoins-modal-options {
  display: flex;
  justify-content: space-around;
  gap: 1rem;
  flex-wrap: wrap;
}

.buymysticoins-modal-option {
  background: rgba(17, 16, 16, 0.8);
  padding: 1rem;
  border-radius: 12px;
  flex: 1 1 250px;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.buymysticoins-modal-option-featured {
  background: rgba(21, 21, 21, 0.6);
  box-shadow: 
      0 0 5px rgba(0, 0, 0, 0.1),
      0 0 3px #ff36ab,
      0 0 7px #ff36ab;
}

.buymysticoins-modal-option-title {
  font-family: var(--title-font);
  margin-bottom: 0.5rem;
  font-size: 1.4rem;
  font-weight: 700;
  background: linear-gradient(90deg, #5932e6, #FF1493, #ff8fab);
  background-size: 300%;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  animation: gradient-animation 5s ease infinite;
}

@keyframes gradient-animation {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.buymysticoins-modal-option-price {
  font-family: var(--body-font);
  font-size: 2.5rem;
  font-weight: bold;
  color: #affc41;
  margin-bottom: 0.5rem;
  margin-top: 0;
}

.buymysticoins-modal-option-features {
  list-style-type: none;
  padding: 0;
  margin-bottom: 0.7rem;
  font-size: 1rem;
  font-family: var(--body-font);
  text-wrap: balance;
}

.buymysticoins-modal-option-feature {
  margin-bottom: 0.3rem;
}

.buymysticoins-modal-payment-options {
  display: flex;
  gap: 0.7rem;
  margin-bottom: 1rem;
}

.buymysticoins-modal-payment-icon {
  font-size: 1.4rem;
  color: var(--bitcoin-orange);
  transition: transform 0.3s ease;
}

.buymysticoins-modal-payment-icon:hover {
  transform: scale(1.1);
}

.buymysticoins-modal-payment-icon.lightning-icon {
  color: #e4ff1a;
  animation: lightning-flash 1.5s infinite;
}

@keyframes lightning-flash {
  0%, 100% { opacity: 1; transform: scale(1); }
  50% { opacity: 0.7; transform: scale(1.2); }
}

.buymysticoins-modal-payment-icon.recommended::after {
  content: 'Recommended';
  position: absolute;
  top: -15px;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--lightning-yellow);
  color: var(--primary-color);
  padding: 2px 5px;
  border-radius: 3px;
  font-size: 0.6em;
  white-space: nowrap;
}

.buymysticoins-modal-subscribe-button {
  background-color: #ff36ab;
  color: #000000;
  border: none;
  padding: 0.4rem 0.8rem;
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  font-size: 0.8rem;
  font-family: var(--body-font);
  font-weight: 600;
}

.buymysticoins-modal-subscribe-button:hover {
  background-color: var(--cyberpunk-pink);
  transform: scale(1.03);
}

.buymysticoins-modal-close-button {
  background-color: #353535;
  color: #ffffff;
  border: none;
  padding: 0.4rem 0.8rem;
  border-radius: 15px;
  cursor: pointer;
  margin-top: 1rem;
  transition: background-color 0.3s ease, color 0.3s ease;
  font-size: 0.8rem;
  font-family: var(--body-font);
}

.buymysticoins-modal-close-button:hover {
  background-color: #b90e4a;
  color: #ffffff;
}

.buymysticoins-modal-plan-icon {
  font-size: 1.8rem;
  color: #237be8;
  margin-bottom: 0.4rem;
}

/* Desktops and large screens */
@media screen and (min-width: 1200px) {
  .buymysticoins-modal-content {
    max-width: 1100px;
  }
}

/* Laptops and smaller desktops */
@media screen and (max-width: 1199px) and (min-width: 992px) {
  .buymysticoins-modal-content {
    max-width: 950px;
  }
}

/* Tablets and small laptops */
@media screen and (max-width: 991px) and (min-width: 768px) {
  .buymysticoins-modal-content {
    max-width: 720px;
    padding: 1.2rem;
  }

  .buymysticoins-modal-option {
    flex-basis: calc(50% - 1rem);
    max-width: none;
  }

  .buymysticoins-modal-title {
    font-size: 2rem;
  }
}

/* Large phones and small tablets */
@media screen and (max-width: 767px) and (min-width: 576px) {
  .buymysticoins-modal-content {
    width: 95%;
    max-width: 540px;
    padding: 1rem;
  }

  .buymysticoins-modal-options {
    flex-direction: column;
  }

  .buymysticoins-modal-option {
    width: 100%;
    max-width: none;
    margin-bottom: 1rem;
  }

  .buymysticoins-modal-title {
    font-size: 1.8rem;
  }
}

/* Phones */
@media screen and (max-width: 575px) {
  .buymysticoins-modal-overlay {
    align-items: flex-start;
    padding: 0.5rem 0;
  }

  .buymysticoins-modal-content {
    width: 95%;
    max-width: none;
    max-height: calc(100vh - 1rem);
    padding: 0.8rem;
    border-radius: 1.2rem;
  }

  .buymysticoins-modal-title {
    font-size: 1.6rem;
  }

  .buymysticoins-modal-option-title {
    font-size: 1.2rem;
  }

  .buymysticoins-modal-option-price {
    font-size: 1.6rem;
  }

  .buymysticoins-modal-option-features {
    font-size: 0.75rem;
  }

  .buymysticoins-modal-payment-icon {
    font-size: 1.6rem;
    margin-bottom: 0.6rem;
    margin-top: 1rem;
  }

  .buymysticoins-modal-subscribe-button,
  .buymysticoins-modal-close-button {
    font-size: 0.9rem;
    padding: 0.3rem 0.6rem;
    margin-bottom: 1.5rem;
    margin-top: 1rem;
    max-width: 70%;
    min-width: 120px;
  }

  .buymysticoins-modal-message {
    font-size: 0.85rem;
  }

  .buymysticoins-modal-plan-icon {
    font-size: 3rem;
    margin-top: 0.6rem;
  }
}

/* Extra small phones */
@media screen and (max-width: 360px) {
  .buymysticoins-modal-content {
    padding: 0.6rem;
  }

  .buymysticoins-modal-title {
    font-size: 1.5rem;
  }

  .buymysticoins-modal-option-title {
    font-size: 1.2rem;
  }

  .buymysticoins-modal-option-price {
    font-size: 1.5rem;
  }

  .buymysticoins-modal-option-features {
    font-size: 0.8rem;
  }
}